 import {  createStore  } from 'vuex'
 import createPersistedState from 'vuex-persistedstate'

 const vuex = createStore({
     plugins: [createPersistedState()],
     state: {
         agence: '',
         token: null,
         basket: [],
         temporal: null,
         quote: null,
         coupon: null,
         filtre: null,
         update: null,
         userForDevis: null,
         pieces: null,
         wallet: null
     },

     mutations: {
         save_devis_user (state, o) {
             state.userForDevis = o
         },

         save_wallet (state, o) {
             state.wallet = o
         },

         save_pieces (state, o) {
             state.pieces = o
         },

         save_temporal (state, o) {
             state.temporal = o
         },

         save_update (state, o) {
             state.update = o
         },

         save_filtre (state, o) {
             state.filtre = o
         },

         save_coupon (state, o) {
             state.coupon = o
         },

         save_quote (state, o) {
             state.quote = o
         },

         save_agence (state, position) {
             state.agence = position
         },

         save_basket (state, v) {
             state.basket.push(v)
         },

         rewrite_basket (state, v) {
             state.basket = v
         },

         save_token (state, position) {
             state.token = position
         },

         logout (state) {
             state.token = null
             state.agence = ''
             state.basket = []
             state.temporal = null
             state.quote = null
             state.coupon = null
             state.filtre = null
             state.update = null
             state.userForDevis = null
             state.pieces = null
         },
     },

     actions: {
         saveWallet ({ commit }, d) {
             commit('save_wallet', d)
         },
         saveTemporalItem ({ commit }, d) {
             commit('save_temporal', d)
         },
         savePieces({ commit }, d) {
             commit('save_pieces', d)
         },
         saveDevisUser ({ commit }, d) {
             commit('save_devis_user', d)
         },
         saveUpdate ({ commit }, d) {
             commit('save_update', d)
         },
         saveFiltre ({ commit }, d) {
             commit('save_filtre', d)
         },
         saveQuote ({ commit }, d) {
             commit('save_quote', d)
         },
         saveCoupon ({ commit }, d) {
             commit('save_coupon', d)
         },
         addToBasket ({ commit }, d) {
             commit('save_basket', d)
         },
         saveBasket ({ commit }, d) {
             commit('rewrite_basket', d)
         },
         saveAgence ({ commit }, a) {
             commit('save_agence', a)
         },
         saveToken ({ commit }, c) {
             commit('save_token', c)
         },
         logout ({ commit }, e) {
             commit('logout', e)
         },
     },

     getters: {
         trackAgence: state => state.agence,
         token: state => state.token,
         trackBasket: state => state.basket,
         trackTemporal: state => state.temporal,
         trackQuote: state => state.quote,
         coupon: state => state.coupon,
         filtre: state => state.filtre,
         update: state => state.update,
         userForDevis: state => state.userForDevis,
         trackPieces: state => state.pieces,
         trackWallet: state => state.wallet
     }
 })

 export default vuex
