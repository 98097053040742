import { createRouter, createWebHistory } from 'vue-router';
import pathLoader from '../plugins/loader'
import store from '../store'

const routes = [
    {
        path: '/',
        name: 'homePrivate',
        component: pathLoader(null, 'homePrivate'),
        redirect: 'dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: pathLoader('dashboard'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/clients',
                name: 'clients',
                component: pathLoader('clients'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/devis',
                name: 'devis',
                component: pathLoader('devis'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/commandes',
                name: 'commandes',
                component: pathLoader('commandes'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/detailsDevis',
                name: 'detailsDevis',
                component: pathLoader('detailsDevis'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/detailsCommande',
                name: 'detailsCommande',
                component: pathLoader('detailsCommande'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/article',
                name: 'article',
                component: pathLoader('article'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/caisse',
                name: 'caisse',
                component: pathLoader('caisse'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/transfere',
                name: 'transfere',
                component: pathLoader('transfere'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/remise',
                name: 'remise',
                component: pathLoader('remise'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/mobileTransfere',
                name: 'mobileTransfere',
                component: pathLoader('mobileTransfere'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/historique',
                name: 'historique',
                component: pathLoader('historique'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/caisseDetail',
                name: 'caisseDetail',
                component: pathLoader('caisseDetail'),
                meta: {
                    requiresAuth: true
                }
            },

        ]
    },
    {
        path: '/',
        name: 'homeDevis',
        component: pathLoader(null, 'homeDevis'),
        redirect: 'nouveauDevis',
        children: [
            {
                path: '/nouveauDevis',
                name: 'nouveauDevis',
                component: pathLoader('nouveauDevis'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/panier',
                name: 'panier',
                component: pathLoader('panier'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/modifierDevis',
                name: 'modifierDevis',
                component: pathLoader('modifierDevis'),
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: pathLoader('login'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    // history: createWebHashHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.token) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router;
