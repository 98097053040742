import { createApp } from 'vue'
import App from './App.vue'
import rooter from '../src/router/index'
import install from './install'
import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'
import 'material-icons/iconfont/material-icons.css'
import i18n from './i18n'
import vuex from "./store"
// import VueApexCharts from "vue3-apexcharts"
import easySpinner from 'vue-easy-spinner'
import Switches from 'vue-switches'
import vClickOutside from "click-outside-vue3"
// import VCalendar from 'v-calendar'
// import 'v-calendar/dist/style.css'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


createApp(App)
    .use(i18n)
    .use(vuex)
    // .use(VueApexCharts)
    .use(easySpinner, {
        /*options*/
        prefix: 'easy',
    })
    // .use(VCalendar, {})
    .use(vClickOutside)
    .use(Switches)
    .use(VueSvgIconPlugin, { tagName: 'icon' })
    .use(install)
    .use(rooter)
    .component('VueDatePicker', VueDatePicker)
    .mount('#app')
